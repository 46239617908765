import React from "react"
import Layout from "../../../components/layout"
import { Link } from 'gatsby';

const BackPain = () => (
  <Layout>
    <section className="section-padding-large biq-bg-prim biq-text-white">
      <div className="container">
        <h1>Back Pains</h1>
      </div>
    </section>
    <section className="section-padding">
      <div className="container">
        <h2 className="mt-6">Your Back</h2>
        <div className="flex mt-6">
          Horizontal Breathing is essential to your back health. Dysfunctional
          Vertical breathing exacerbates neck and shoulder pain, lower and
          mid-back pain and tightness.
        </div>
        <h3 className="text-left mt-6">Your diaphragm loves your spine</h3>
        <div className="flex mt-6">
          Your diaphragm and back are irrevocably linked, as the diaphragm’s
          ligaments connect to your spinal column at both the thoracic (T12)
          vertebrae and your lumbar (L1 and L2) vertebrae, joining it to both
          the middle and the lower back. The ramifications of associative
          problems are extensive.
        </div>
        <h3 className="text-left mt-6">Breathing helps lubricate joints</h3>
        <div className="flex mt-6">
          Breathing dysfunctionally restricts the body flexibility that keeps
          joints lubricated and muscles supple (through extension and blood
          flow). The spine doesn’t get the requisite movement, pressure, and
          support of the breath with every inhale and exhale (which is why you
          keep feeling as if you need back support from a chair back or
          compressive garments). The muscles of the pelvis and the pelvic floor
          are restricted in their range of motion, which leads to pelvic floor
          imbalance and lower-back discomfort.
        </div>

        <h4 className="text-left mt-6">The Research</h4>
        <div className="flex mt-6">
          A study in the Clinical Journal of Pain concluded that breathing
          problems increased the risk of back pain and vice versa. Stress in the
          body raises cortisol in the muscles and therefore places strain on the
          back through excess diaphragm tension. (What this means is that
          negative emotions -- sadness, anger-- hurt your back!)
        </div>
        <div className="flex mt-6">
          Conversely, research shows that low-back pain has a drastic impact on
          how you breathe; it reduces the size of the diaphragm and raises it in
          the chest cavity so that your breath becomes shallower. In fact, the
          diaphragm is much more easily fatigued in people with back pain,
          causing faster, shallower breathing, contributing to overall fatigue,
          and trapping you into a loop of pain and fatigue.
        </div>

        <h3 className="text-left mt-6">Physical and Emotional Tension</h3>
        <div className="flex mt-6">
          The concept that tension—both physical and emotional—causes back pain
          (and disease) important! Dr. John E. Sarno has given the name “Tension
          Myositis Syndrome” to the pain that comes when cells and tissues in
          the back tighten and get less and less oxygen.42 Tightening can be
          because of a combination of physical and emotional pain. Better
          inhales and exhales are part of the solution; addressing stress levels
          is a big part of the cure.
        </div>
        <h4 className="text-left mt-6">The Research</h4>
        <div className="flex mt-6">
          Several studies (e.g., Catherine Woodyard, “Exploring the Therapeutic
          Effects of Yoga and its Ability to Increase Quality of Life,”
          International Journal of Yoga 4, no. 2 [2011]: 49–54) have shown that
          breathing exercises (when combined with yoga and meditation) reduce
          pain and emotional symptoms 2.5 times more effectively than
          physiotherapy exercise even with additional counseling and therapy.
          Breathing, yoga, and meditation were 1.5 times better at returning
          mobility to chronic low-back pain sufferers. Read that paragraph above
          again, it’s mindblowing!
        </div>
        <div className="flex mt-6">
          Turn that frown, upside down. A study conducted by Daniel McIntosh and
          his colleagues in the journal Cognition and Emotion purports that
          facial expressions can alter the volume of air inhaled through the
          nose, which in turn influences brain temperature and affective states.
        </div>

        <h3 className="text-left mt-6">Taking Care of Your Back: What to do</h3>
        <div className="flex mt-6">
          Start with Diaphragm Extensions. Once you are comfortable with the
          inhale being an expansion of belly (a pushing upwards or the weight or
          books) and the exhale is a drop of the weight and narrowing of your
          body, include your pelvis: On the inhale, intentionally relax
          (“soften”) your glutes, hips, pelvic floor, and thighs. Notice how
          naturally your hips tip. Exhale, give your lower abs, pelvic floor,
          and glutes a little squeeze, and notice how your hips tip under and
          the space between your lower back and the floor narrows. Then
          exaggerate the rhythm. You’ll find that the movement in your hips,
          though subtle, is quite natural and goes along with the breath.
        </div>
        <h3 className="text-left mt-6">Detox Multitask</h3>
        <div className="flex mt-6">
          Horizontal breathing is the best multitask health hack there is -- not
          only is it the support your back has been needing, it is the
          self-massage that comes with the wavelike motion of breathing with
          your entire body beneficial for gastric peristalsis, a very precise
          calming effect on your neurological system and it is a natural detox
          flushing lymph out of your body with every breath.
        </div>

        <h3 className="text-left mt-6">Sore the next day?</h3>
        <div className="flex mt-6">
          The years of keeping your lower back stiff means even this small
          movement can be a big deal for your back. In addition, if you have
          been “guarding” this part of your body because it is an “emotional”
          place for you then your subconscious may try to sabotage your attempts
          at changing the status quo. Don’t give up, slow down and make changes
          in smaller increments.
        </div>
        <h3 className="text-left mt-6">Want More</h3>
        <div className="flex mt-6">
          <ol>
            <li>
              Get Rock and Roll, and Cat Cow into your daily routine. Remember
              it is the same exact movement as Diaphragm Extensions, just in a
              different position. Be gentle with yourself as sometimes just
              changing the angle of your body means you have to readjust and
              practice again.
            </li>
            <li>
              Your BIQ is an A and you can do all the warmup exercises with
              ease. Want more back information? Look up spine expert Dr. Stuart
              McGill, who can give you an exercise protocol for strengthening
              and prevention now that your back and your breath are aligned.
            </li>
          </ol>
        </div>
        <h3 className="text-left mt-6">Pain in the neck (And Shoulders?)</h3>
        <div className="flex mt-6">
          It is funny how we think that neck and shoulder pain are just a part
          of modern life. The discomfort may range from constant stiffness to
          pain that spreads from to the very back of your skull.
        </div>
        <div className="flex mt-6">
          Though much neck and shoulder pain come from badly aligned heads and
          necks, lack of movement at the computer or in the car, this situation
          is only made worse when you also use these muscles mistakenly as
          primary breathing muscles. Hence, no matter how many massages you
          have, how many therapeutic pillows or ergonomic chairs you buy, you’ll
          continue to have neck and shoulder discomfort until you change your
          breathing back to a diaphragmatic breath.
        </div>
        <div className="flex mt-6">
          <ol>
            <li>
              Check yourself for Forward Head Posture and mouth breathing. Both
              significantly affect your head and neck’s alignment. In fact, the
              adage in medical school reminds us how the much your spine’s
              vertebrae and nerves affect breathing: “C3, 4, 5, keep the
              diaphragm alive.”
            </li>
            <li>
              Massage your upper pecs. Tight upper pecs keep your shoulders
              internally rotated and your body’s attempt to adjust leads to more
              dysfunction.
            </li>
            <li>
              Autocorrect, autocorrect. Make a commitment to move to a
              Horizontal Breath.
            </li>
            <li>
              Roll your shoulders “out” throughout the day, reminding yourself
              that routine breathing should be that middle widening and
              narrowing.
            </li>
            <li>
              Having trouble moving from a Hybrid to a Horizontal breather and
              really letting your neck and shoulders just be neck and shoulders?
              Make sure you understand your diaphragm’s attachment to the pelvic
              floor, and consider a Feet Up or home made alternative.
            </li>
          </ol>
        </div>
      </div>
    </section>
    <section
          id="biqMockup"
          className="section-padding biq-bg-gray"
          // style={{
          //   background: `url(${this.props.data.biqBackground.edges[0].node.childImageSharp.fluid.src})`,
          //   backgroundSize: 'cover',
          //   backgroundPosition: 'center',
          //   backgroundBlendMode: 'darken',
          //   backgroundPositionY: '1',
          // }}
        >
          <div className="flex absolute">
            {/* <div className="w-full">
              <Img
                fluid={
                  this.props.data.biqBackground.edges[0].node.childImageSharp
                    .fluid
                }
              />
            </div> */}
          </div>
          <div className="container">
            {/* BIQ  */}
            <div className="flex flex-row flex-wrap my-24">
              {/* <div className="flex w-full xl:w-1/2"> */}
              {/* <div className="w-full">
                  <Img
                    fluid={
                      this.props.data.biqMobile.edges[0].node.childImageSharp
                        .fluid
                    }
                  />
                </div> */}
              {/* </div> */}
              <div className="flex flex-col w-full place-items-center justify-center">
                <h1 className="uppercase biq-text-prim">Take the BIQ</h1>
                <h2 className="flex font-bold my-6 biq-text-xl text-center  biq-text-prim">
                  You can't change what you don't measure
                </h2>
                <span className="flex font-normal my-6">
                  <Link to="/biq" className="btn-prim biq-text-white">
                    Find Out Your BreathingIQ
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </section>
  </Layout>
)

export default BackPain